import { Controller } from "@hotwired/stimulus"
import copy from 'copy-to-clipboard';

export default class extends Controller {
  connect() {
    console.log("HELLO Copy connected..")
  }

  copyText(event) {
    console.log('das..')
    const textToCopy = event.target.dataset.copyValue;
    copy(textToCopy);
    event.preventDefault();
    toastr.info('Copied to clipboard')
  }
}
