import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [];
  static values = {
    id: String,
    clientId: String,
    recordingType: String
  };
  activeRequests = 0;

  connect() {
    console.log('converation controller connected..', this.recordingTypeValue)
    this.activeRequests = 0;
    this.form = $(this.element);
    this.timeout = null
  }


  submitForm(e) {
    var vm = this;
    e.preventDefault();

    var isValid = this.form[0].reportValidity();
    if (!isValid) { return; }
    console.log('class list;', this.form[0].classList);

    document.querySelectorAll('.audio-field').forEach(function (element) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(element, 'audio-recording');
      var isRecording = destCtrl.openRecorder();
      if (isRecording) {
        isValid = false;
        alert('Please stop all recordings before continuing');
      }
    });

    if (!isValid || this.form[0].classList.contains('invalid')) return;

    var data = this.form_data(e);

    data.append('commit', 'Submit');

    console.log('saving')

    var url = `/customer/clients/${this.clientIdValue}/conversations`
    clearTimeout(this.timeout);

    if (this.recordingTypeValue == 'main') {
    } else {
      url += `/${this.idValue}/supplementary_recordings`
    }

    this.timeout = setTimeout(() => {
      this.activeRequests += 1;

      $.ajax({
        url: url,
        method: 'POST',
        data: data,
        processData: false,
        contentType: false,
        dataType: "script",
        mimeType: 'multipart/form-data',    //Property added in 1.5.1
        beforeSend: () => {
          if ($('.submit-btn')[0]) {
            $('.submit-btn')[0].disabled = true;
            $('.submit-btn')[0].value = 'Saving..';
          }
        },
        success: () => {
          console.log('doom..')
        },
        error: () => {
        },
        complete: () => {
          this.activeRequests -= 1;
          if ($('.submit-btn')[0] && this.activeRequests == 0) {
            $('.submit-btn')[0].disabled = false;
            $('.submit-btn')[0].value = 'Save Conversation';
          }
        }
      });
    }, 1000)
  }

  serializeFormParams() {
    return this.serializeFormData(this.form);
  }


  serializeFormData(form) {
    var data = $(form).serializeArray().reduce(function (output, value) {
      if (output[value.name]) {
        if (Object.prototype.toString.call(output[value.name]) == '[object Array]') {
          var arr = output[value.name];
          arr.push(value.value)
          output[value.name] = arr;
        } else {
          var str = output[value.name];
          var arr = str.split(',');
          arr.push(value.value);
          output[value.name] = arr;
        }
      } else {
        output[value.name] = value.value
      }
      return output;
    }, {})

    return data;
  }

  form_data(e) {
    var vm = this;
    var obj = new FormData(this.form[0]);
    document.querySelectorAll('.audio-field').forEach(function (element) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(element, 'audio-recording');
      obj = destCtrl.appendFormData(obj);
    });

    console.log('obect:', obj);
    return obj;
  }
}
